// Dependencies
import React from 'react'
import get from 'lodash/get'
import { graphql } from 'gatsby'

// Components
import Layout from '../components/layout'
import Hero from '../components/Hero'
import Gallery from '../components/Gallery'
import Helmet from '../components/Helmet'
import Social from '../components/Social'

// Helpers
import { getNavigationProps, getExtraSections } from '../utils'

const GalleryPage = (props) => {
  const gallerySlug = get(props, 'pageContext.uid')
  const extraGalleries = get(props, 'data.allPrismicGalleriesContent')

  const galleriesUidLanguages =
    Array.isArray(extraGalleries.nodes) &&
    Array.isArray(extraGalleries.nodes[0].alternate_languages) &&
    extraGalleries.nodes[0].alternate_languages.map((lang) => {
      return {
        lang: lang.lang,
        uid: lang.uid,
      }
    })

  const langLinks = [
    {
      url: props.path.replace(`/${props.pageContext.lang}`, ''),
      lang: props.pageContext.lang,
      active: true,
    },
    ...get(props, 'data.prismicGalleryContent.alternate_languages').map(
      (lang) => {
        const galleriesUid = galleriesUidLanguages.filter(
          (slug) => slug.lang === lang.lang
        )[0].uid

        return {
          ...lang,
          url: `/${galleriesUid}${lang.url}`,
        }
      }
    ),
  ]

  extraGalleries.nodes[0].data.galleries = [
    ...extraGalleries.nodes[0].data.galleries.filter(
      (item) => item.gallery.uid !== gallerySlug
    ),
  ]

  const sections = [
    extraGalleries,
    get(props, 'data.allPrismicTestimonialsContent'),
    get(props, 'data.allPrismicServicesContent'),
  ]

  const navigationProps = getNavigationProps(props)

  const followCopy = get(
    props,
    'data.allPrismicAboutContent.nodes[0].data.follow_copy.html'
  )

  const heroCopy = get(props, 'data.prismicGalleryContent.data.hero_copy')
  const heroImage = get(
    props,
    'data.prismicGalleryContent.data.hero_image.document.data'
  )

  const introTitle = get(props, 'data.prismicGalleryContent.data.intro_title')
  const introCopy = get(
    props,
    'data.prismicGalleryContent.data.intro_copy.html'
  ).replace(/(https?:\/\/.\/)/gim, '/')

  const images = get(props, 'data.prismicGalleryContent.data.gallery_images')
  const galleryTitle = get(
    props,
    'data.prismicGalleryContent.data.gallery_title'
  )
  const thumbs = get(props, 'data.prismicGalleryContent.data.gallery_thumbs')
  const galleryProps = {
    galleryTitle,
    introTitle,
    introCopy,
    images,
    thumbs,
  }

  const heroProps = {
    title: heroCopy,
    copy: images.length + ' Photos',
    image: heroImage,
  }

  const generalContent = get(
    props,
    'data.allPrismicGeneralContent.nodes[0].data'
  )

  const socialProps = {
    instagram: navigationProps.instagram,
    facebook: navigationProps.facebook,
    followCopy,
    latestPostsLabel: generalContent.latest_posts_label,
    loadMorePosts: generalContent.load_more_posts,
  }

  return (
    <Layout {...{ ...navigationProps, langLinks }}>
      <Helmet {...generalContent} />
      <Hero {...heroProps} compact />
      <Gallery {...galleryProps} />
      {getExtraSections(sections, generalContent, props.pageContext.lang)}
      <Social {...socialProps} />
    </Layout>
  )
}

export const galleryPageQuery = graphql`
  query($uid: String, $lang: String) {
    allPrismicNavigation(filter: { lang: { eq: $lang } }) {
      nodes {
        lang
        data {
          links {
            page {
              uid
              lang
            }
            label
          }
        }
      }
    }
    allPrismicGeneralContent(filter: { lang: { eq: $lang } }) {
      nodes {
        data {
          ...generalContent
        }
      }
    }
    allPrismicAboutContent(filter: { lang: { eq: $lang } }) {
      nodes {
        data {
          follow_copy {
            html
          }
        }
      }
    }
    prismicGalleryContent(uid: { eq: $uid }, lang: { eq: $lang }) {
      uid
      alternate_languages {
        url
        lang
        uid
      }
      data {
        intro_title
        intro_copy {
          html
        }
        gallery_title
        gallery_images {
          image {
            fluid(imgixParams: { q: 100 }) {
              ...fluidImage
            }
          }
        }
        gallery_thumbs: gallery_images {
          image {
            fluid(maxWidth: 400, imgixParams: { q: 100 }) {
              ...fluidImage
            }
          }
        }
        hero_copy
        hero_image {
          document {
            ... on PrismicHeroImage {
              data {
                portrait {
                  fluid(maxWidth: 1650, imgixParams: { q: 100 }) {
                    ...fluidImage
                  }
                }
                landscape {
                  fluid(maxWidth: 2800, imgixParams: { q: 100 }) {
                    ...fluidImage
                  }
                }
              }
            }
          }
        }
      }
    }
    allPrismicServicesContent(filter: { lang: { eq: $lang } }) {
      __typename
      nodes {
        url
        data {
          ...servicesContent
        }
      }
    }
    allPrismicTestimonialsContent(filter: { lang: { eq: $lang } }) {
      __typename
      nodes {
        url
        data {
          ...testimonialsContent
        }
      }
    }
    allPrismicGalleriesContent(filter: { lang: { eq: $lang } }) {
      __typename
      nodes {
        url
        uid
        data {
          ...galleriesContent
        }
        alternate_languages {
          url
          lang
          uid
        }
      }
    }
  }
`

export default GalleryPage
