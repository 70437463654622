// Dependencies
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { PhotoSwipe } from 'react-photoswipe'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

// Styles
import './gallery.scss'

// Components
import { previewSVG } from '../../assets/svgs'

const Gallery = ({ introTitle, introCopy, galleryTitle, images, thumbs }) => {
  let [isOpen, setIsOpen] = useState(false)
  const baseOptions = {
    closeOnScroll: false,
    history: false,
    preload: [3, 3],
    closeEl: true,
    captionEl: true,
    fullscreenEl: true,
    zoomEl: true,
    shareEl: false,
    counterEl: true,
    arrowEl: true,
    preloaderEl: true,
  }
  let [options, setOptions] = useState({
    index: 1,
    ...baseOptions,
  })

  let items = images.map((item) => {
    return {
      src: item.image.fluid.src,
      w: 1600,
      h: 1600 / item.image.fluid.aspectRatio,
    }
  })

  const handleClose = () => {
    setIsOpen(false)
  }

  const openGallery = (index) => {
    setOptions({
      index,
      ...baseOptions,
    })
    setIsOpen(true)
  }

  return (
    <>
      <section>
        <div className="section-content padded">
          {(introTitle || introCopy) && (
            <>
              <h2>{introTitle}</h2>
              <div
                className="compact"
                dangerouslySetInnerHTML={{ __html: introCopy }}
              />
            </>
          )}
          {images && images.length > 0 && (
            <ul className="gallery">
              {thumbs.map((image, index) => {
                const key =
                  'gallery-image_' + introTitle.replace(' ', '') + '_' + index

                return (
                  <li key={key} className="card gallery__item">
                    <button
                      onClick={() => {
                        trackCustomEvent({
                          category: 'Gallery Item',
                          action: 'Click',
                          label: galleryTitle,
                          value: index,
                        })
                        openGallery(index)
                      }}
                    >
                      <div className="card__picture">
                        <Img fluid={image.image.fluid} />
                        <div dangerouslySetInnerHTML={{ __html: previewSVG }} />
                      </div>
                    </button>
                  </li>
                )
              })}
            </ul>
          )}
        </div>
      </section>
      <PhotoSwipe
        isOpen={isOpen}
        items={items}
        options={options}
        onClose={handleClose}
      />
    </>
  )
}

// Components PropTypes
Gallery.propTypes = {
  galleryTitle: PropTypes.string.isRequired,
  introTitle: PropTypes.string.isRequired,
  introCopy: PropTypes.string,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      fluid: PropTypes.shape({
        src: PropTypes.string,
        srcSet: PropTypes.string,
        srcWebp: PropTypes.string,
        srcSetWebp: PropTypes.string,
        sizes: PropTypes.string,
        aspectRatio: PropTypes.number,
        base64: PropTypes.string,
      }),
    })
  ),
  thumbs: PropTypes.arrayOf(
    PropTypes.shape({
      fluid: PropTypes.shape({
        src: PropTypes.string,
        srcSet: PropTypes.string,
        srcWebp: PropTypes.string,
        srcSetWebp: PropTypes.string,
        sizes: PropTypes.string,
        aspectRatio: PropTypes.number,
        base64: PropTypes.string,
      }),
    })
  ).isRequired,
}
Gallery.defaultProps = {
  introCopy: '',
}

export default Gallery
